<template>
  <div class="scroll-behavior">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ScrollBehavior",

  props: {
    threshold: {
      required: true,
    },
  },

  mounted() {
    window.addEventListener('scroll', this.scrollCallback);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollCallback);
  },

  methods: {
    scrollCallback() {
      let block = this.$el;
      let blockBottomPos = block.offsetTop + block.scrollHeight;
      let scrolledHeight = window.pageYOffset + window.innerHeight;

      if(blockBottomPos - scrolledHeight < this.threshold)
        this.$emit('scrolled');
    },
  },
}
</script>

<style scoped>

</style>