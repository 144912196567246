<template>
  <div class="load-photo">
    <div>{{label}}</div>
    <div class="img-cont">
      <DropZone @send="sendPhoto">
        <ProductImage v-if="value" :image="value" class="photo-view" />
        <img src="https://via.placeholder.com/100&text=Photo"
             @click="change"
             alt=""
             class="photo-view photo-view--empty"
             v-else
        >
      </DropZone>

      <input type="file"
             accept="image/png,image/jpeg,image/gif"
             class="file-input"
             ref="fileInput"
             @change="sendPhoto($event.target.files)"
      >

      <LoaderProgress :loaded-percent="loadingProgress" v-if="!isNaN(parseInt(loadingProgress))"/>
    </div>
    <div>
      <button class="btn btn-link" @click="change">Изменить</button>
      <button class="btn btn-link text-danger" @click="startRemove">Удалить</button>
    </div>


  </div>
</template>

<script>
import DropZone from "@/components/templates/products/DropZone";
import LoaderProgress from "@/components/templates/widgets/LoaderProgress";
import ProductImage from "@/components/templates/products/ProductImage";
import ErrorProcessing from "@/utils/ErrorProcessing";

export default {
  name: "LoadSinglePhoto",

  components: {
    DropZone, LoaderProgress, ProductImage,
  },

  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    loadingProgress: {},
    type: {
      type: String,
      default: 'other',
    },
  },

  methods: {
    startRemove() {
      if(!confirm('Удалить фото?'))
        return;

      this.$emit('remove');
    },

    change() {
      this.$refs.fileInput.click();
    },

    async sendPhoto(files) {
      if(!files.length) {
        return;
      }

      if(this.type === 'main_photo') {
        await this.validateDimension(files[0]);
      }

      
      this.$emit('send', files[0]);
    },

    async validateDimension(file) {
        
          return new Promise((resolve) => {
            let image = new Image();
            let reader = new FileReader();

            reader.onload = (e) => {
                image.src = e.target.result;

                image.onload = () => {
                  
                  console.log(image.width, image.height);
                    if (image.width !== image.height) {
                      ErrorProcessing.showError('Выберите квадратное фото');
                    } else {
                      resolve();
                    }
                };

                
            };

            reader.readAsDataURL(file);
          });
          
    },
  },
}
</script>

<style scoped lang="scss">
  .load-photo{
    width: 120px;
    text-align: center;
    margin: 10px;
  }

  .photo-view{
    width: 100px;
    height: 100px;
    object-fit: cover;

    &--empty{
      &:hover{
        cursor: pointer;
        opacity: .8;
      }
    }
  }

  .dragover{
    .photo-view{
      outline: 5px #2459c4 solid;
    }
  }

  .file-input{
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    position: absolute;
    z-index: -10;
  }

  .img-cont{
    position: relative;
  }
</style>