<template>
  <div>
    <h1>Бренды</h1>

    <div class="brands-list">
      <router-link :to="{name: 'brand', params: {id: brand.id || 0}}"
                   class="brand-link"
                   v-for="brand in brands"
                   :key="brand.id || ''"
      >
        <span>{{brand.name || '(Без названия)'}}</span>
        <Counters
          :new-count="brand.counters[0]"
          :photographed-count="brand.counters[1]"
          :processed-count="brand.counters[2]"
          :loaded-count="brand.counters[3]"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import apiRequest from "@/utils/apiRequest";
import Counters from "@/components/templates/Counters";

export default {
  name: "Brands",

  components: {
    Counters,
  },

  data() {
    return {
      brands: [],
    };
  },

  created() {
    this.loadBrands();
  },

  methods: {
    loadBrands() {
      apiRequest.get('/brands').then(brands => {
        this.brands = brands;
      });
    },
  },
}
</script>

<style scoped lang="scss">
  .brands-list{
    display: flex;
    flex-wrap: wrap;
    margin: 20px -10px;
  }

  .brand-link{
    flex: 380px 1 0;
    display: flex;
    align-items: flex-start;
    margin: 10px;
  }
</style>