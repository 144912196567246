<template>
  <label class="d-block">
    <select class="form-control"
            :value="value"
            @change="$emit('change', $event.target.value)">
      <option :value="null">---</option>
      <option v-for="category in categories"
              :key="category.id"
              :value="category.id"
      >{{category.name}}</option>
    </select>
  </label>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: "SelectCategory",

  props: {value: {}},

  computed: {
    ...mapState('categories', ['categories']),
  },
}
</script>

<style scoped>

</style>