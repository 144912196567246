<template>
  <div class="container-fluid">
    <label>
      <input type="checkbox" v-model="withLoaded">
      Показывать загруженные партии
    </label>
    <label class="ml-3">
      <input type="checkbox" v-model="withHidden">
      Показывать партии в архиве
    </label>

    <ScrollBehavior :threshold="100" @scrolled="onScrolled">
      <PartsList :parts="parts" @refresh="refresh" @change="bindChange"/>
    </ScrollBehavior>
  </div>
</template>

<script>
import apiRequest from "@/utils/apiRequest";
import PartsList from "@/components/templates/parts/PartsList";
import ScrollBehavior from "@/components/templates/widgets/ScrollBehavior";

export default {
  name: "Parts",

  components: {
    PartsList, ScrollBehavior,
  },

  data() {
    return {
      parts: [],
      offset: 0,
      scrollLock: false,
      loaded: false,
      withLoaded: false,
      withHidden: false,
    };
  },

  created() {
    this.loadParts();
  },

  watch: {
    withLoaded() {
      this.loaded = false;
      this.parts = [];
      this.offset = 0;
      this.scrollLock = false;
      this.loadParts();
    },

    withHidden() {
      this.loaded = false;
      this.parts = [];
      this.offset = 0;
      this.scrollLock = false;
      this.loadParts();
    },
  },

  methods: {
    loadParts() {
      let url = '/parts?offset=' + this.offset;
      if(this.withLoaded)
        url += '&with_loaded';
      if(this.withHidden)
        url += '&with_hidden';

      return apiRequest.get(url).then(parts => {
        this.parts = [
            ...this.parts,
            ...parts,
        ];
        if(!parts.length) {
          this.loaded = true;
        }else{
          this.offset += 15;
        }
      });
    },

    refresh() {
      this.scrollLock = true;
      let offset = this.offset;
      let url = '/parts?limit=' + (offset + 15);
      if(this.withLoaded)
        url += '&with_loaded';
      if(this.withHidden)
        url += '&with_hidden';

      apiRequest.get(url).then(parts => {
        this.parts = parts;
      });
    },

    onScrolled() {
      if(this.loaded || this.scrollLock)
        return;
      this.scrollLock = true;
      this.loadParts().finally(() => {
        this.scrollLock = false;
      });
    },

    bindChange(part, data) {
      let index = this.parts.findIndex(p => p.id === part.id);
      if(index > -1) {
        let part = {...this.parts[index], ...data};
        this.$set(this.parts, index, part);
      }
    },
  },
}
</script>

<style scoped>

</style>