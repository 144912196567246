import Router from 'vue-router';
import Vue from 'vue';

Vue.use(Router);

import Main from "../components/pages/Main";
import Part from "@/components/pages/Part";
import Product from "@/components/pages/Product";
import Brands from "@/components/pages/Brands";
import Brand from "@/components/pages/Brand";

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'main',
      component: Main,

      meta: {
        title: 'Главная',
      },
    },
    {
      path: '/brands',
      name: 'brands',
      component: Brands,

      meta: {
        title: 'Бренды',
      },
    },
    {
      path: '/part/:id',
      name: 'part',
      component: Part,

      meta: {
        title: 'Партия',
      },
      props: route => ({...route.params, ...route.query}),
    },
    {
      path: '/product/:id',
      name: 'product',
      component: Product,

      meta: {
        title: 'Фото товара',
      },
      props: true,
    },
    {
      path: '/brand/:id',
      name: 'brand',
      component: Brand,

      meta: {
        title: 'Бренд - партии',
      },
      props: true,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const title = to.meta.title;
  if (title) {
    document.title = title +
      ' | Zenn';
  }

  next();
});

// function castIdRouteParams(route) {
//   return {
//     id: parseInt(route.params.id),
//   };
// }

export default router;
