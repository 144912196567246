<template>
  <div>
    <div v-for="(product) in products" :key="product.id">
      <ProductEdit :product="product"
                   :collapse="true"
                   @refreshProduct="$emit('refreshProduct', $event)"
                   @changeStatus="$emit('changeStatus', product, $event)"
                   @changeCategory="$emit('changeCategory', product, $event)"
      />
    </div>
  </div>
</template>

<script>
import ProductEdit from "@/components/templates/products/ProductEdit";

export default {
  name: "ProductsList",

  components: {
    ProductEdit,
  },

  props: {
    products: {
      type: Array,
    },
  },
}
</script>

<style scoped>

</style>