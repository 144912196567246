import Vue from 'vue'
import App from './App.vue'
import './css/style.scss';

Vue.config.productionTip = false

import router from './router/index';
import store from './store';

import toastr from 'toastr/build/toastr.min';
window.toastr = toastr;

window.$ = require('jquery');

require('./utils/filters');
import apiRequest from './utils/apiRequest';

window.apiRequest = apiRequest;
window.ErrorProcessing = require('./utils/ErrorProcessing').default;
Vue.prototype.$eventBus = new Vue();

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

