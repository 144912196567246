import store from '../store';
import {getApiHost} from "@/utils/urls";
window.store = store;
export default new(class ApiRequest {
  /**
   * GET query
   *
   * @param {string} url
   * @param {object} params
   * @return {Promise<unknown>}
   */
  get(url, params = {}) {
    url = this.resolveUrl(url);
    return new Promise((resolve, reject) => {
      window.$.ajax({
        ...this.baseRequest(resolve, reject, params),
        url,
        type: 'GET',
      });
    });
  }

  /**
   * POST query
   *
   * @param {string} url
   * @param {object} data
   * @return {Promise<unknown>}
   */
  post(url, data) {
    url = this.resolveUrl(url);
    return new Promise((resolve, reject) => {
      const params = {
        ...this.baseRequest(resolve, reject),
        url,
        type: 'POST',
        data,
      };

      if (data instanceof FormData) {
        params.processData = false;
        params.contentType = false;
      }

      window.$.ajax(params);
    });
  }

  /**
   * DELETE query
   *
   * @param {string} url
   * @param {object} params
   * @return {Promise<unknown>}
   */
  delete(url, params = {}) {
    url = this.resolveUrl(url);
    return new Promise((resolve, reject) => {
      window.$.ajax({
        ...this.baseRequest(resolve, reject, params),
        url,
        type: 'DELETE',
      });
    });
  }

  /**
   * Make base request with default settings
   *
   * @param {function} resolve
   * @param {function} reject
   * @return {{headers: {Accept: string}, success: success, error: error}}
   */
  baseRequest(resolve, reject) {
    return {
      headers: {
        Accept: 'application/json',
        Authorization: store.state.auth.token,
      },
      xhrFields: {
        withCredentials: true
      },
      success: function(response) {
        resolve(response);
      },
      error: function(xhr) {
        if(xhr.status === 401) {
          store.dispatch('auth/logout');
        }
        store.commit('SET_PROCESSING', false);
        reject(xhr);
      },
    };
  }

  resolveUrl(url) {
    if(url[0] === '/')
      return getApiHost() + '/apix/domi' + url;
    return url;
  }
})
