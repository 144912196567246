var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mt-1 mb-1"},[_c('div',{staticClass:"card-header part-header"},[_c('strong',[_c('router-link',{attrs:{"to":{name: 'part', params: {id: _vm.part.id}}}},[_vm._v(_vm._s(_vm.part.name))]),(_vm.inSelection)?_c('label',{staticClass:"selection"},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.selected},on:{"change":function($event){return _vm.changeSelection($event.target.checked)}}})]):_vm._e()],1),_c('div',{staticClass:"imports"},[(!_vm.inSelection)?_c('button',{staticClass:"btn btn-link",on:{"click":_vm.startUnion}},[_vm._v("Объединить")]):_vm._e(),_c('a',{staticClass:"btn btn-link",attrs:{"href":_vm.csvUrl,"target":"_blank","rel":"nofollow noopener"}},[_vm._v("Скачать CSV")]),_c('a',{staticClass:"btn btn-link",attrs:{"href":_vm.xlsxUrl,"target":"_blank","rel":"nofollow noopener"}},[_vm._v("Скачать XLSX")]),(!_vm.part.hide)?_c('button',{staticClass:"btn btn-link",on:{"click":_vm.toArchive}},[_vm._v("В архив")]):_c('button',{staticClass:"btn btn-link",on:{"click":_vm.fromArchive}},[_vm._v("Вернуть из архива")])])]),_c('div',{staticClass:"card-body part-info"},[(_vm.inSelection)?_c('div',{staticClass:"part-info__overlay"}):_vm._e(),_c('p',[_c('span',{staticClass:"pr-2"},[_vm._v("Всего товаров: "+_vm._s(_vm.part.products.length))]),(_vm.manCount)?[_c('router-link',{attrs:{"to":{name: 'part', params: {id:_vm.part.id}, query: {sex: 1}}}},[_vm._v("мужских")]),_vm._v(" - "+_vm._s(_vm.manCount)+" ")]:_vm._e(),(_vm.womanCount)?[_c('router-link',{attrs:{"to":{name: 'part', params: {id: _vm.part.id}, query: {sex: 2}}}},[_vm._v("женских")]),_vm._v(" - "+_vm._s(_vm.womanCount)+" ")]:_vm._e()],2),(_vm.part.categories.length)?_c('ul',_vm._l((_vm.part.categories),function(category){return _c('li',{key:category.id},[_c('router-link',{attrs:{"to":{
          name: 'part',
          params: {id: _vm.part.id},
          query: {category: category.id}
        }}},[_vm._v(_vm._s(category.name))]),_vm._v(" - "+_vm._s(category.all)+" "),(category.man || category.woman)?_c('span',{staticClass:"category-counters"},[(category.man)?_c('span',{staticClass:"category-counter"},[_c('router-link',{attrs:{"to":{
              name: 'part',
              params: {id: _vm.part.id},
              query: {category: category.id, sex: 1}
            }}},[_vm._v("М")]),_vm._v(" - "+_vm._s(category.man)+" ")],1):_vm._e(),(category.woman)?_c('span',{staticClass:"category-counter"},[_c('router-link',{attrs:{"to":{
              name: 'part',
              params: {id: _vm.part.id},
              query: {category: category.id, sex: 2}
            }}},[_vm._v("Ж")]),_vm._v(" - "+_vm._s(category.woman)+" ")],1):_vm._e()]):_vm._e()],1)}),0):_vm._e(),_c('Counters',{attrs:{"new-count":_vm.newCount,"photographed-count":_vm.photographedCount,"processed-count":_vm.processedCount,"loaded-count":_vm.loadedCount}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }