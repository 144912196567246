<template>
  <div>
    <div v-if="brand">
      <h1>Бренд {{brand.name || '(без названия)'}} - партии</h1>

      <label>
        <input type="checkbox" v-model="withLoaded">
        Показывать загруженные партии
      </label>
      <label class="ml-3">
        <input type="checkbox" v-model="withHidden">
        Показывать партии в архиве
      </label>

      <ScrollBehavior :threshold="100" @scrolled="onScrolled">
        <PartsList :parts="parts" @refresh="refresh" @change="bindChange"/>
      </ScrollBehavior>
    </div>
  </div>
</template>

<script>
import apiRequest from "@/utils/apiRequest";
import PartsList from "@/components/templates/parts/PartsList";
import ScrollBehavior from "@/components/templates/widgets/ScrollBehavior";

export default {
  name: "Brand",

  components: {
    PartsList, ScrollBehavior,
  },

  props: {
    id: {
      required: true,
    },
  },

  data() {
    return {
      brand: null,
      parts: [],
      scrollLock: false,
      offset: 0,
      loaded: false,
      withLoaded: false,
      withHidden: false,
    };
  },

  created() {
    this.loadBrand();
    this.loadParts();
  },

  beforeRouteUpdate(to, from, next) {
    next();
    this.$nextTick(() => {
      this.loadBrand();
      this.loadParts();
    });
  },

  watch: {
    withLoaded() {
      this.loaded = false;
      this.parts = [];
      this.offset = 0;
      this.scrollLock = false;
      this.loadParts();
    },

    withHidden() {
      this.loaded = false;
      this.parts = [];
      this.offset = 0;
      this.scrollLock = false;
      this.loadParts();
    },
  },

  methods: {
    loadBrand() {
      apiRequest.get('/brand/' + this.id).then(brand => {
        this.brand = brand;
        document.title = `Бренд ${brand.name} - партии`;
      });
    },

    loadParts() {
      let url = `/brand/${this.id}/parts?offset=${this.offset}`;
      if(this.withLoaded)
        url += '&with_loaded';
      if(this.withHidden)
        url += '&with_hidden';

      return apiRequest.get(url).then(parts => {
        this.parts = [
            ...this.parts,
            ...parts,
        ];
        if(!parts.length) {
          this.loaded = true;
        }else{
          this.offset += 15;
        }
      });
    },

    refresh() {
      this.scrollLock = true;
      let limit = this.offset + 15;
      let url = `/brand/${this.id}/parts?limit=${limit}`;
      if(this.withLoaded)
        url += '&with_loaded';
      if(this.withHidden)
        url += '&with_hidden';

      apiRequest.get(url).then(parts => {
        this.parts = parts;
      });
    },

    onScrolled() {
      if(this.loaded || this.scrollLock)
        return;
      this.scrollLock = true;
      this.loadParts().finally(() => {
        this.scrollLock = false;
      });
    },

    bindChange(part, data) {
      let index = this.parts.findIndex(p => p.id === part.id);
      if(index > -1) {
        let part = {...this.parts[index], ...data};
        this.$set(this.parts, index, part);
      }
    },
  },
}
</script>

<style scoped>

</style>