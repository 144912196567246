import apiRequest from "@/utils/apiRequest";

export default {
    namespaced: true,

    state: {
        categories: [],
    },

    mutations: {
        SET_CATEGORIES(state, categories){
            state.categories = categories;
        },
    },

    actions: {
        async load({commit}) {
            let categories = await apiRequest.get('/categories');
            commit('SET_CATEGORIES', categories);
        },
    },
};