<template>
  <div id="app">
    <template v-if="!loading">
      <template v-if="isAuthenticated">
        <TopBar/>
        <div class="container">
          <router-view/>
        </div>
      </template>
      <Login v-else/>
      <div class="processing-loader" v-if="$store.state.processing"></div>
    </template>
    <div class="user-loader" v-else></div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import Login from "@/components/pages/Login";
import TopBar from "@/components/templates/TopBar";

export default {
  name: 'App',

  components: {
    Login, TopBar,
  },

  created() {
    this.$store.dispatch('loadData');
    this.$store.dispatch('categories/load');
    this.$store.dispatch('auth/loadUser').catch(() => {
      // try refresh
      this.$store.dispatch('auth/refresh');
    });
  },

  computed: {
    ...mapState('auth', ['loading']),
    ...mapGetters('auth', ['isAuthenticated']),
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.container{
  padding-top: 70px;
}

.processing-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
  background-image: url(assets/loader.svg);
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1000000000000;
}

.user-loader{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(assets/loader.svg);
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1000000000000;
}

</style>
