<template>
  <nav class="navbar navbar-dark bg-dark fixed-top top-bar">
    <div class="left">
      <router-link class="navbar-brand" to="/">Zenn</router-link>
      <router-link class="text-white" to="/brands">Бренды</router-link>
      <a href="#" class="text-white" @click.prevent="logout">Выход</a>
    </div>

    <Counters
       v-if="counters"
      :new-count="counters[0]"
      :photographed-count="counters[1]"
      :processed-count="counters[2]"
      :loaded-count="counters[3]"
      :show-all="true"
      :expand="true"
    />

    <ProductSearch/>
  </nav>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import ProductSearch from "@/components/templates/products/ProductSearch";
import Counters from "@/components/templates/Counters";

export default {
  name: "TopBar",

  components: {
    ProductSearch, Counters,
  },

  computed: {
    ...mapState(['counters']),
  },

  methods: {
    ...mapActions('auth', ['logout']),
  },
}
</script>

<style scoped lang="scss">
  .top-bar{
    display: flex;
    justify-content: space-between;
    color: #fff;
  }

  .left a{
    margin: 0 .5em;
  }
</style>