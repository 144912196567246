<template>
  <div>
    <p class="lead">
      <span>Видео Vimeo</span>
      <button class="btn btn-link" @click.prevent="save">Сохранить</button>
    </p>
    <label class="form-group d-block">
      <input type="text" class="form-control" v-model="link">
    </label>

    <iframe class="video-frame"
            v-if="link"
            :src="link.replace('javascript:', '')"
            frameborder="0"
            width="200"
            height="500"
    ></iframe>
  </div>
</template>

<script>

export default {
  name: "VimeoInput",

  props: {
    defaultValue: {},
  },

  data() {
    return {
      link: this.$props.defaultValue,
    };
  },

  methods: {
    save() {
      this.$emit('save', {vimeo: this.link});
    },
  },
}
</script>

<style scoped lang="scss">
  .video-frame{
    width: 100%;
  }
</style>