<template>
  <div class="counters" :class="{'not-expand': !expand}">
    <div class="counter" v-if="newCount || showAll">
      <div class="counter__count counter__count--red">{{newCount}}</div>
      <div class="counter__label" v-if="expand">новых</div>
    </div>
    <div class="counter" v-if="photographedCount || showAll">
      <div class="counter__count counter__count--orange">{{photographedCount}}</div>
      <div class="counter__label" v-if="expand">сфотографированных</div>
    </div>
    <div class="counter" v-if="processedCount || showAll">
      <div class="counter__count counter__count--blue">{{processedCount}}</div>
      <div class="counter__label" v-if="expand">обработанных</div>
    </div>
    <div class="counter" v-if="loadedCount || showAll">
      <div class="counter__count counter__count--success">{{loadedCount}}</div>
      <div class="counter__label" v-if="expand">загруженных</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Counters",

  props: {
    newCount: {},
    photographedCount: {},
    processedCount: {},
    loadedCount: {},
    showAll: {
      type: Boolean,
      default: false,
    },
    expand: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
.counters{
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  margin: 0 .5em;
}

.counter{
  display: flex;
  font-size: 14px;
  margin: 0 .7em;

  &__count{
    margin-right: .3em;
    line-height: 1;
    padding: .3em .5em;
    border-radius: 8px;
    color: #fff;

    &--red{
      background: #af2e2e;
    }

    &--orange{
      background: orange;
    }

    &--blue{
      background: #3a65db;
    }

    &--success{
      background: #235f23;
    }
  }
}

.not-expand{
  .counter{
    margin: 0 .1em;
    &__count{
      margin: 0;
    }
  }
}
</style>