<template>
  <DropZone @send="sendFiles">
    <label class="input-file-drag" ref="dropZone">
      <input type="file"
             accept="image/png,image/jpeg,image/gif"
             multiple
             @change="bindChange">
      <img src="../../assets/upload.svg"
           alt="Выберите файл"
           class="upload-icon"
      >
    </label>
  </DropZone>
</template>

<script>
import DropZone from "@/components/templates/products/DropZone";

export default {
  name: "PhotoLoader",

  components: {
    DropZone,
  },

  methods: {

    sendFiles(files) {
      if(!files.length)
        return;

      this.$emit('send', files);
    },

    async bindChange(e) {
      this.sendFiles(e.target.files);
    },

  },
}
</script>

<style scoped lang="scss">
  .input-file-drag{
    user-select: none;
    padding: .5em;

    input{
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      position: absolute;
      z-index: -10;

      &:focus ~ .upload-icon{
        outline: 1px #555 solid;
      }
    }
  }

  .upload-icon{
    width: 100px;
    height: 100px;
    opacity: .5;

    &:hover{
      opacity: 1;
      cursor: pointer;
    }
  }

  .dragover{
    background: #328bce;
    .upload-icon{
      opacity: 1;
      filter: brightness(0) invert(1);
    }
  }
</style>