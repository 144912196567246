import Vue from 'vue';

Vue.filter('formatDate', function (dateString) {
    if(!dateString)
        return '';
    let date = new Date(Date.parse(dateString));
    return date.toLocaleDateString();
});

Vue.filter('sexLabel', sex => {
    if(!sex)
        return '-';
    return parseInt(sex) === 1 ? 'М' : 'Ж';
});