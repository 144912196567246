<template>
  <div>
    <div class="login-cont">
      <form class="login-form" @submit.prevent="send">
        <h1>Авторизация</h1>
        <div class="form-group">
          <label for="admin-login-login">Телефон</label>
          <TelMask type="tel"
                 class="form-control"
                 id="admin-login-login"
                 placeholder="Введите телефон"
                 v-model="form.phone"
                 required
                 pattern="\+7\(\d\d\d\) \d\d\d-\d\d-\d\d"
                 name="tel"
          />
        </div>
        <a href="#" class="btn btn-link mb-2" v-show="step" @click.prevent="requestCode()">Запросить код ещё раз</a>

        <div class="form-group" v-if="step">
          <label for="admin-login-password">Смс-код</label>
          <input type="number"
                 class="form-control"
                 id="admin-login-password"
                 placeholder="Введите код из смс"
                 v-model="form.code"
                 required
                 maxlength="4"
                 pattern="\d\d\d\d"
                 name="code"
          >
        </div>
        <button class="btn btn-primary">{{ loginLabel }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import TelMask from "@/components/templates/widgets/TelMask";
import apiRequest from "@/utils/apiRequest";
import ErrorProcessing from "@/utils/ErrorProcessing";
import {getApiHost} from "@/utils/urls";

export default {
  name: "Login",

  components: {
    TelMask,
  },

  data(){
    return {
      form: {
        phone: null,
        code: null,
      },
      step: 0,
    };
  },

  computed: {
    loginLabel() {
      return this.step ? 'Войти' : 'Запросить код';
    },

    clearPhone() {
      return this.form.phone.replace(/[^0-9]/g, '');
    },
  },

  methods: {
    send(){
      if(this.step === 0) {
        this.requestCode();
      }else{
        this.confirm();
      }
    },

    requestCode() {
      apiRequest.post(getApiHost() + '/apix/v2/auth/request-code', {
        phone_number: this.clearPhone,
      }).then(result => {
        if(result.type !== 'login') {
          ErrorProcessing.showError('Вы не зарегистрированы');
          return;
        }

        this.step = 1;
        this.$set(this.form, 'code', '');
      }).catch(xhr => {
        if(xhr.status === 429) {
          ErrorProcessing.showError('Подождите минуту, не так быстро');
        }else{
          ErrorProcessing.showXHRError(xhr);
        }
      });
    },

    confirm() {
      apiRequest.post(getApiHost() + '/apix/v2/auth/confirm', {
        phone_number: this.clearPhone,
        code: this.form.code,
      }).then(result => {
        this.$store.commit('auth/AUTH_SUCCESS', result.token);
        this.$store.dispatch('auth/loadUser');
      }).catch(xhr => {
        if(xhr.status === 429) {
          ErrorProcessing.showError('Вы уже 5 раз ввели неверный код, запросите новый');
        }else if(xhr.status === 403){
          ErrorProcessing.showError('Неправильный код');
        }else{
          ErrorProcessing.showXHRError(xhr);
        }
      });
    },
  },
}
</script>

<style scoped lang="scss">
.login-cont{
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form{
  padding: 2em;
  border: 1px #ccc solid;
  box-shadow: 0 0 10px 2px #ccc;
}
</style>