<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DropZone",

  mounted() {
    this.dropZone = window.$(this.$el);
    this.dropZone.on('drag dragstart dragend dragover dragenter dragleave drop', this.cancelDefaultAction);
    this.dropZone.on('dragover dragenter', this.bindDragover);
    this.dropZone.on('dragleave', this.bindDragleave);
    this.dropZone.on('drop', this.bindDrop);
  },

  beforeDestroy() {
    this.dropZone.off('drag dragstart dragend dragover dragenter dragleave drop', this.cancelDefaultAction);
    this.dropZone.off('dragover dragenter', this.bindDragover);
    this.dropZone.off('dragleave', this.bindDragleave);
    this.dropZone.off('drop', this.bindDrop);
  },

  methods: {
    cancelDefaultAction() {
      return false;
    },

    bindDragover() {
      this.dropZone.addClass('dragover');
    },

    bindDragleave(e) {
      let dx = e.pageX - this.dropZone.offset().left;
      let dy = e.pageY - this.dropZone.offset().top;
      if ((dx < 0) || (dx > this.dropZone.width()) || (dy < 0) || (dy > this.dropZone.height())) {
        this.dropZone.removeClass('dragover');
      }
    },

    bindDrop(e) {
      this.dropZone.removeClass('dragover');
      let files = e.originalEvent.dataTransfer.files;
      this.$emit('send', files);
    },
  },
}
</script>

<style scoped>

</style>