import Vuex from 'vuex';
import Vue from 'vue';
import apiRequest from "@/utils/apiRequest";
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

import auth from './modules/auth';
import categories from "@/store/modules/categories";

export default new Vuex.Store({
  modules: {
    auth, categories,
  },
  strict: debug,

  state: {
    processing: false,
    counters: null,
  },

  mutations: {
    SET_PROCESSING(state, processing) {
      state.processing = processing;
    },

    SET_COUNTERS(state, counters) {
      state.counters = counters;
    },
  },

  actions: {
    async loadData({commit}) {
      const data = await apiRequest.get('/data');
      commit('SET_COUNTERS', data.counters);
    },
  },
})
