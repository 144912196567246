import apiRequest from "@/utils/apiRequest";

/**
 *
 * @param {File} file
 * @param {Number} productId
 * @param {String} type
 * @param {function} progressCallback
 * @returns {Promise<void>}
 */
export default async function (file, productId, type, progressCallback = null) {
    let extension = file.name.match(/\.(\w+)$/)[1];
    let fileType = extension === 'png' ? 'image/png' : 'image/jpeg';
    let {uploadUrl, path} = await getUploadUrl(extension, productId, type);

    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.open('PUT', uploadUrl);
        xhr.upload.onprogress = (e) => {
            console.log(e);
            if(typeof progressCallback === 'function')
                progressCallback(e);
        };
        xhr.onload = async () => {
            let result = await apiRequest.post('/upload', {
                type, productId, path,
            });
            resolve(result);
        };
        xhr.onerror = () => {reject(xhr)};
        xhr.onabort = () => {reject(xhr)};
        xhr.send(new Blob([file], {type: fileType}));
    });
}

async function getUploadUrl(extension, productId, type) {
    return await apiRequest.post('/upload/get-signed-url', {
        extension, type, productId,
    });
}