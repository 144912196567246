<template>
  <div class="progress-cont">
    <div class="progress">
      <div class="loaded" :style="{width: loadedPercent + '%'}"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoaderProgress",
  
  props: {
    loadedPercent: {},
  },
}
</script>

<style scoped lang="scss">
  .progress-cont {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .5);
  }
  
  .progress{
    position: absolute;
    top: calc(50% - 10px);
    left: 15px;
    height: 20px;
    width: calc(100% - 30px);
    background: #fff;
    border-radius: 10px;
  }
  
  .loaded{
    background: #356aef;
  }
</style>