<template>
    <input type="text">
</template>

<script>
  import initTelMask from "@/utils/tel-mask";

  export default {
    name: 'TelMask',

    props: {
      value: {},
    },

    mounted() {
      let input = this.$el;
      input.value = this.value || '';
      this.mask = initTelMask(input);
      this.mask.on('accept', () => {
        this.$emit('input', this.mask.value);
      });
    },

    watch: {
      value(value){
        if(this.mask){
          this.mask.value = value;
        }
      },
    },
  };
</script>

<style scoped>

</style>