import { render, staticRenderFns } from "./PartsList.vue?vue&type=template&id=613f49a2&scoped=true&"
import script from "./PartsList.vue?vue&type=script&lang=js&"
export * from "./PartsList.vue?vue&type=script&lang=js&"
import style0 from "./PartsList.vue?vue&type=style&index=0&id=613f49a2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "613f49a2",
  null
  
)

export default component.exports