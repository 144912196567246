<template>
  <div>
    <p class="lead">
      <span>Видео Youtube</span>
      <button class="btn btn-link" @click.prevent="save">Сохранить</button>
    </p>
    <label class="form-group d-block">
      <input type="text" class="form-control" v-model="link">
    </label>

    <iframe class="video-frame"
            v-if="videoId"
            :src="'https://www.youtube.com/embed/' + videoId"
            frameborder="0"
            width="420"
            height="300"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "YoutubeInput",

  props: {
    defaultValue: {},
  },

  data() {
    return {
      link: this.$props.defaultValue,
    };
  },

  computed: {
    videoId() {
      let regex = /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\/|watch\?v=|watch\?.+&v=))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//;
      if(!regex.test(this.link))
        return null;
      return this.link.match(regex)[1];
    },
  },

  methods: {
    save() {
      this.$emit('save', {video: this.link});
    },
  },
}
</script>

<style scoped lang="scss">
  .video-frame {
    width: 100%;
  }
</style>