<template>
  <img :src="imageUrl" alt="" ref="image">
</template>

<script>
import {buildProductUrl} from "@/utils/urls";

export default {
  name: "ProductImage",

  props: {
    image: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      imageUrl: buildProductUrl(this.$props.image),
    };
  },

  mounted() {
    this.$refs.image.addEventListener('error', this.fallbackImage);
  },

  beforeDestroy() {
    this.$refs.image.removeEventListener('error', this.fallbackImage);
  },

  methods: {
    fallbackImage() {
      this.imageUrl = buildProductUrl(this.image, 0);
    },
  },

  watch: {
    image() {
      this.imageUrl = buildProductUrl(this.image);
    },
  },
}
</script>

<style scoped>

</style>