<template>
  <div class="card mt-1 mb-1">
    <div class="card-header part-header">
      <strong>
        <router-link
            :to="{name: 'part', params: {id: part.id}}"
        >{{part.name}}</router-link>

        <label v-if="inSelection" class="selection">
          <input type="checkbox" :checked="selected" @change="changeSelection($event.target.checked)">
        </label>
      </strong>

      <div class="imports">
        <button class="btn btn-link" @click="startUnion" v-if="!inSelection">Объединить</button>
        <a :href="csvUrl" class="btn btn-link" target="_blank" rel="nofollow noopener">Скачать CSV</a>
        <a :href="xlsxUrl" class="btn btn-link" target="_blank" rel="nofollow noopener">Скачать XLSX</a>
        <button v-if="!part.hide" class="btn btn-link" @click="toArchive">В архив</button>
        <button v-else class="btn btn-link" @click="fromArchive">Вернуть из архива</button>
      </div>
    </div>
    <div class="card-body part-info">
      <div class="part-info__overlay" v-if="inSelection"></div>
      <p>
        <span class="pr-2">Всего товаров: {{part.products.length}}</span>
        <template v-if="manCount">
          <router-link
              :to="{name: 'part', params: {id:part.id}, query: {sex: 1}}"
          >мужских</router-link> - {{manCount}}
        </template>
        <template v-if="womanCount">
          <router-link
              :to="{name: 'part', params: {id: part.id}, query: {sex: 2}}"
          >женских</router-link> - {{womanCount}}
        </template>
      </p>

      <ul v-if="part.categories.length">
        <li v-for="category in part.categories" :key="category.id">
          <router-link :to="{
            name: 'part',
            params: {id: part.id},
            query: {category: category.id}
          }">{{category.name}}</router-link> - {{category.all}}
          <span class="category-counters" v-if="category.man || category.woman">
            <span class="category-counter" v-if="category.man">
              <router-link :to="{
                name: 'part',
                params: {id: part.id},
                query: {category: category.id, sex: 1}
              }">М</router-link>
              - {{category.man}}
            </span>
            <span class="category-counter" v-if="category.woman">
              <router-link :to="{
                name: 'part',
                params: {id: part.id},
                query: {category: category.id, sex: 2}
              }">Ж</router-link>
              - {{category.woman}}
            </span>
          </span>
        </li>
      </ul>

      <Counters
        :new-count="newCount"
        :photographed-count="photographedCount"
        :processed-count="processedCount"
        :loaded-count="loadedCount"
      />
    </div>
  </div>
</template>

<script>
import Counters from "@/components/templates/Counters";
import {getApiHost} from "@/utils/urls";
import apiRequest from "@/utils/apiRequest";

export default {
  name: "PartsListItem",

  components: {
    Counters,
  },

  props: {
    part: {
      type: Object,
      required: true,
    },

    inSelection: {
      default: true,
    },

    selected: {
      default: false,
    },
  },

  computed: {
    manCount() {
      return this.part.products.filter(product => product.sex === 1).length;
    },
    womanCount() {
      return this.part.products.filter(product => product.sex === 2).length;
    },

    newCount() {
      return this.part.products.filter(p => p.load_status === 0).length;
    },

    photographedCount() {
      return this.part.products.filter(p => p.load_status === 1).length;
    },

    processedCount() {
      return this.part.products.filter(p => p.load_status === 2).length;
    },

    loadedCount() {
      return this.part.products.filter(p => p.load_status === 3).length;
    },

    queryString() {
      return `id=${this.part.id}`;
    },

    csvUrl() {
      return getApiHost() + `/apix/domi/import/part?` + this.queryString;
    },

    xlsxUrl() {
      return getApiHost() + `/apix/domi/import/part?format=xlsx&` + this.queryString;
    },
  },

  methods: {
    startUnion() {
      this.$emit('startUnion');
    },

    changeSelection(checked) {
      if(checked) {
        this.$emit('select');
      }else{
        this.$emit('unselect');
      }
    },

    toArchive() {
      apiRequest.post('/parts/change-hidden', {
        id: this.part.id,
        hidden: 1,
      }).then(res => {
        this.$emit('change', res);
      });
    },

    fromArchive() {
      apiRequest.post('/parts/change-hidden', {
        id: this.part.id,
        hidden: 0,
      }).then(res => {
        this.$emit('change', res);
      });
    },
  },
}
</script>

<style scoped lang="scss">
  .category-counters{
    margin: 0 1em;
  }

  .category-counter{
    margin: 0 .5em;
  }

  .part-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  //.part-info{
  //  position: relative;
  //
  //  &__overlay{
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    width: 100%;
  //    height: 100%;
  //    background: rgba(255, 255, 255, .5);
  //  }
  //}

  .selection{
    margin-left: 1em;
    margin-bottom: 0;
    vertical-align: middle;
    line-height: 1;

    input{
      width: 30px;
      height: 30px;
    }
  }
</style>