<template>
  <div>

    <LoadTypePhotos label="Обычная модель"
                    type="model_photo"
                    :photos="modelPhotos"
                    :product-id="product.id"
                    @remove="removePhoto"
                    @loaded="bindLoaded"
    />

    <LoadTypePhotos label="Женщина-модель для унисексов"
                    type="female"
                    :photos="femalePhotos"
                    :product-id="product.id"
                    @remove="removePhoto"
                    @loaded="bindLoaded"
    />

    <LoadTypePhotos label="Фото со спины"
                    type="back"
                    :photos="backPhotos"
                    :product-id="product.id"
                    @remove="removePhoto"
                    @loaded="bindLoaded"
    />

    <LoadTypePhotos label="Детали"
                    type="detail"
                    :photos="detailPhotos"
                    :product-id="product.id"
                    @remove="removePhoto"
                    @loaded="bindLoaded"
    />

    <div class="load-product-photos">
      <LoadSinglePhoto
          label="Для главной"
          :value="mainPhoto"
          :loading-progress="loadings.main_photo"
          :type="`main_photo`"
          @send="setPhoto('main_photo', $event)"
          @remove="removeCommonPhoto('main_photo')"
      />
      <LoadSinglePhoto
          label="Look"
          :value="lookPhoto"
          :loading-progress="loadings.look"
          @send="setPhoto('look', $event)"
          @remove="removeCommonPhoto('look')"
      />
      <LoadSinglePhoto
          label="Перед"
          :value="product.large_image"
          :loading-progress="loadings.large_image"
          @send="setPhoto('large_image', $event)"
          @remove="removeCommonPhoto('large_image')"
      />
      <LoadSinglePhoto
          label="Лук"
          :value="product.small_image"
          :loading-progress="loadings.small_image"
          @send="setPhoto('small_image', $event)"
          @remove="removeCommonPhoto('small_image')"
      />
      <LoadSinglePhoto
          label="Промо"
          :value="product.promo_image"
          :loading-progress="loadings.promo_image"
          @send="setPhoto('promo_image', $event)"
          @remove="removeCommonPhoto('promo_image')"
      />
    </div>

    <div class="videos-input">
      <VimeoInput class="video-input" :default-value="product.vimeo" @save="save"/>
      <YoutubeInput class="video-input" :default-value="product.video" @save="save"/>
    </div>
  </div>
</template>

<script>
import apiRequest from "@/utils/apiRequest";
import LoadTypePhotos from "@/components/templates/products/LoadTypePhotos";
import LoadSinglePhoto from "@/components/templates/products/LoadSinglePhoto";
import VimeoInput from "@/components/templates/VimeoInput";
import YoutubeInput from "@/components/templates/YoutubeInput";
import uploadToS3 from "@/utils/uploadToS3";

export default {
  name: "LoadProductPhotos",

  components: {
    LoadTypePhotos, LoadSinglePhoto, VimeoInput, YoutubeInput,
  },

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loadings: {},
    };
  },

  computed: {
    modelPhotos() {
      return this.product.photos.filter(photo => {
        return photo.model_photo === 1 && photo.female !== 1;
      });
    },

    femalePhotos() {
      return this.product.photos.filter(photo => {
        return photo.female === 1;
      });
    },

    backPhotos() {
      return this.product.photos.filter(photo => {
        return photo.back === 1;
      });
    },

    detailPhotos() {
      return this.product.photos.filter(photo => {
        return !photo.back && !photo.female && !photo.model_photo && !photo.cover_photo;
      });
    },

    mainPhoto() {
      console.log(this.product.main_photo);
      return this.product.main_photo;
    },

    lookPhoto() {
      return this.product.set?.image;
    },
  },

  methods: {
    bindLoaded() {
      this.$emit('refreshProduct', this.product);
    },

    removePhoto(photo) {
      apiRequest.get('/products/remove-photo/' + photo.product_foto_id).then(() => {
        this.$emit('refreshProduct', this.product);
      });
    },

    removeCommonPhoto(type) {
      apiRequest.get(`/products/remove-common-photo?product_id=${this.product.id}&type=${type}`).then(() => {
        this.$emit('refreshProduct', this.product);
      });
    },

    setPhoto(type, file) {
      uploadToS3(file, this.product.id, type, e => {
        let loadedPercent = e.loaded / e.total * 100;
        this.$set(this.loadings, type, loadedPercent);
      })
          .then(() => {
            this.bindLoaded();
            this.$set(this.loadings, type, null);
          })
          .catch(xhr => {
            window.ErrorProcessing.showXHRError(xhr);
          });
    },

    save(data) {
      apiRequest.post('/products/' + this.product.id, data).then(() => {
        this.$emit('refreshProduct', this.product);
      });
    },
  },
}
</script>

<style scoped lang="scss">
  .load-product-photos{
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }

  .videos-input{
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
  }

  .video-input{
    flex: 200px 1 0;
    margin: 20px;
  }
</style>