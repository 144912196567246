<template>
  <div>
    <div class="union-block" v-if="unionIds.length">
      <template>
        <button class="btn btn-link" @click="unionIds = []">Отменить</button>
        <button class="btn btn-success" @click="saveUnion">Объединить</button>
      </template>
    </div>
    <div v-for="(part, key) in parts" :key="key">
      <PartsListItem :part="part"
                     :in-selection="unionIds.length"
                     :selected="unionIds.indexOf(part.id) > -1"
                     @startUnion="startUnion(part)"
                     @select="select(part)"
                     @unselect="unselect(part)"
                     @change="$emit('change', part, $event)"
      />
    </div>
  </div>
</template>

<script>
import apiRequest from "@/utils/apiRequest";
import PartsListItem from "@/components/templates/parts/PartsListItem";

export default {
  name: "PartsList",

  components: {
    PartsListItem,
  },

  data() {
    return {
      unionIds: [],
    };
  },

  props: {
    parts: {
      type: Array,
    },
  },

  methods: {
    startUnion(part) {
      this.unionIds = [part.id];
    },

    select(part) {
      let index = this.unionIds.indexOf(part.id);
      if(index < 0)
        this.unionIds.push(part.id);
    },

    unselect(part) {
      let index = this.unionIds.indexOf(part.id);
      if(index > -1)
        this.unionIds.splice(index, 1);
    },

    saveUnion() {
      if(this.unionIds.length < 2)
        return;
      apiRequest.post('/parts/join', {ids: this.unionIds}).then(() => {
        this.$emit('refresh');
        this.unionIds = [];
      }).catch(xhr => {
        window.ErrorProcessing.showXHRError(xhr);
      });
    },
  },
}
</script>

<style scoped lang="scss">
  .union-block{
    height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    position: sticky;
    top: 55px;
    background: #fff;
    z-index: 999;
  }
</style>