<template>
  <div class="search-cont">
    <label class="mb-0">
      <input type="text"
             class="form-control"
             placeholder="Поиск (sku/id/Код 1С)"
             v-model="searchText"
             @focus="bindFocus"
      >
    </label>

    <div class="search-results" v-show="onFocus">
      <ProductSearchResult
          v-for="product in searchProducts"
          :key="product.id"
          :product="product"
          :search-text="searchText.trim()"
          @click.native="select(product)"
          @close="close"
      />
    </div>
  </div>
</template>

<script>
import apiRequest from "@/utils/apiRequest";
import ProductSearchResult from "@/components/templates/products/ProductSearchResult";

export default {
  name: "ProductSearch",

  components: {
    ProductSearchResult,
  },

  data() {
    return {
      searchProducts: [],
      searchText: '',
      onFocus: false,
    };
  },

  methods: {
    search() {
      if(!this.searchText) {
        this.searchProducts = [];
        return;
      }

      let searchText = this.searchText;
      apiRequest.get('/products/search?search=' + searchText).then(products => {
        if(searchText.trim() === this.searchText.trim())
          this.searchProducts = products;
      });
    },

    bindFocus() {
      this.onFocus = true;
      this.search();
      document.addEventListener('click', this.bindClick);
    },

    bindClick(e) {
      if(this.$el.contains(e.target) || e.target === this.$el)
        return;

      this.close();
    },

    select(product) {
      this.close();
      this.$router.push({
        name: 'product',
        params: {
          id: product.id,
        },
      });
    },

    close() {
      document.removeEventListener('click', this.bindClick);
      this.onFocus = false;
    },
  },

  watch: {
    searchText() {
      this.search();
    },
  },
}
</script>

<style scoped lang="scss">
  .search-cont{
    position: relative;
  }

  .search-results{
    position: absolute;
    top: 100%;
    right: 0;
    background: #fff;
    border: 1px #ccc solid;
    width: 600px;
    max-width: 100vw;

    &:empty{
      display: none;
    }
  }
</style>