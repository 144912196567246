<template>
  <div class="load-cont">
    <div class="lead type-name">{{ label }}</div>
    <div class="photos-cont">
        <div v-for="photo in photos"
            :key="photo.product_foto_id"
            class="loaded-photo"
        >
          <ProductImage :image="photo.filename"/>
          <div class="remove-btn" @click="startRemove(photo)"></div>
        </div>
      <PhotoLoader v-if="!loadingPhotos.length" @send="loadPhotos"/>
      <div class="loading-image"
           v-for="(item, key) in loadingPhotos"
           :key="key"
      >
        <LoaderProgress
            :loaded-percent="item.loadedPercent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PhotoLoader from "@/components/templates/PhotoLoader";
import LoaderProgress from "@/components/templates/widgets/LoaderProgress";
import ProductImage from "@/components/templates/products/ProductImage";
import uploadToS3 from "@/utils/uploadToS3";

export default {
  name: "LoadTypePhotos",

  components: {
    PhotoLoader, LoaderProgress, ProductImage,
  },

  data() {
    return {
      loadingPhotos: []
    };
  },

  props: {
    label: {
      type: String,
      required: true,
    },

    photos: {
      type: Array,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    productId: {
      required: true,
    },
  },

  watch: {
    photos() {
      this.loadingPhotos = [];
    },
  },

  methods: {
    startRemove(photo) {
      if(!confirm('Удалить фото?'))
        return;

      this.$emit('remove', photo);
    },

    async loadPhotos(files) {
      this.loadingPhotos = [];

      for (let file of files) {
        this.loadingPhotos.push({
          file, loadedPercent: 0,
        });
      }

      for(let key in files) {
        if(!Object.prototype.hasOwnProperty.call(files, key))
          continue;
        let file = files[key];
        try{
          await uploadToS3(file, this.productId, this.type, e => {
            let loadedPercent = e.loaded / e.total * 100;
            this.$set(this.loadingPhotos[key], 'loadedPercent', loadedPercent);
          });
        }catch (xhr) {
          window.ErrorProcessing.showXHRError(xhr);
        }
      }

      this.$emit('loaded');
    },
  },
}
</script>

<style scoped lang="scss">
  .load-cont{
    display: flex;
    align-items: center;
  }

  .photos-cont{
    margin-left: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }
  .loading-image{
    width: 100px;
    height: 100px;
    margin: 5px;
    position: relative;
    background: #eee;
  }
  .loaded-photo{
    width: 100px;
    margin: 5px;
    position: relative;

    img{
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
    
    .remove-btn{
      position: absolute;
      top: 5px;
      right: 5px;
      width: 20px;
      height: 20px;
      background-color: #fff;
      color: red;
      font-size: 20px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transform: rotate(45deg);
      opacity: .6;
      border: 1px #000 solid;

      &:before{
        content: '+';
      }

      &:hover{
        cursor: pointer;
        opacity: 1;
      }
    }
  }

  .type-name{
    width: 200px;
  }
</style>