import store from './../store';

export default class ErrorProcessing {
  /**
   * Show all API errors in 1 format
   *
   * @param {string} error
   */
  static showError(error) {
    window.toastr['error'](error, '', {"positionClass": "toast-bottom-right"});
  }

  /**
   * Show API error from XHR
   *
   * @param {XMLHttpRequest} xhr
   */
  static showXHRError(xhr) {
    let error = this.getErrorMessage(xhr);
    this.showError(error);
  }

  /**
   * Get validation error message string from XMLHttpRequest
   * @param {XMLHttpRequest} xhr
   * @return {string}
   */
  static getValidationError(xhr) {
    let errors = xhr.responseJSON;
    if (typeof errors === 'string') {
      errors = [errors];
    } else if (errors instanceof Array) {
      errors = errors.map(error => {
        if (error instanceof Object) {
          return error.message;
        }
        return error;
      });
    }

    return errors.join('\n');
  }

  /**
   * Get error message string from XMLHttpRequest
   * @param {XMLHttpRequest} xhr
   * @return {string}
   */
  static getErrorMessage(xhr) {
    switch (xhr.status) {
      case 401:
        store.dispatch('auth/logout');
        return 'Недостаточно прав';

      case 403:
        return 'Недостаточно прав';

      case 500:
        return 'На сервере произошла ошибка';

      case 404:
        return 'Запрашиваемый ресурс не найден';

      case 422:
        return this.getValidationError(xhr);

      default:
        return 'Произошла ошибка';
    }
  }
}
