import IMask from "imask";

export default function initTelMask(input) {
    var mask;
    input.addEventListener('input', function() {
        var value = this.value.replace(/[^0-9]/g, '');
        if(value.length <= 10 || mask.masked.isComplete)
            return;

        mask.value = value.substring(value.length - 10);
    });

    mask = IMask(input, {
        mask: '+{7}(000) 000-00-00',
        lazy: false,
    });
    return mask;
}